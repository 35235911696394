// ProductInfo.js
import React, { useState } from "react";
import { InputNumber, notification, Button, message, Rate } from "antd";
import history from "../../utils/history";
import Image from "../Image/Image";
import IsLoading from '../../components/IsLoading'
import {
  Left,
  Main,
  Thumbnails,
  Thumbnail,
  Right,
  CatLabel,
  TitleDetail,
  Price,
  InStock,
  LinkWrapper,
  Discription,
  Heading,
} from "./ProductInfoStyles";

function ProductInfo({
  productDetail,
  reviewList,
  cartList,
  addToCart,
  productId,
}) {
  const [qty, setQty] = useState(1);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  // const productId = match.params.id;

  // change ảnh chi tiết
  const [stt, setStt] = useState(0);
  const bigImage = productDetail?.data?.image || [];
  const handletab = (index) => {
    setStt(index);
  };

  function handleAddToCart() {
    if (!userInfo) {
      const key = `open${Date.now()}`;
      return notification.warning({
        message: "Chưa đăng nhập",
        description: "Bạn cần đăng nhập để thêm vào giỏ hàng",
        key,
        btn: (
          <Button
            type="primary"
            onClick={() => {
              notification.close(key);
              history.push("/login");
            }}
          >
            Đăng nhập
          </Button>
        ),
      });
    } else {
      const existProductIndex = cartList?.data?.findIndex(
        (item) => item.productId === parseInt(productId)
      );
      if (existProductIndex !== -1) {
        const newCartList = cartList.data;
        newCartList.splice(existProductIndex, 1, {
          productId: parseInt(productId),
          count: cartList.data[existProductIndex].count + qty,
          name: productDetail.data.name,
          price: productDetail.data.price,
          image: productDetail.data.image,
          // countInStock: productDetail.data.countInStock,
        });
        addToCart({
          userId: userInfo.id,
          carts: newCartList,
        });
        message.success("Cập nhật giỏ hàng thành công!");
      } else {
        addToCart({
          userId: userInfo.id,
          carts: [
            ...cartList.data,
            {
              productId: parseInt(productId),
              count: qty,
              name: productDetail.data.name,
              price: productDetail.data.price,
              image: productDetail.data.image,
              // countInStock: productDetail.data.countInStock,
            },
          ],
        });
        message.success("Thêm vào giỏ hàng thành công!");
      }
    }
  }

  function onChange(value) {
    setQty(value);
  }

  function renderRateList() {
    var showRate = 0;
    reviewList.data.forEach((item) => {
      showRate = showRate + item.rate;
    });
    return Math.ceil(showRate / reviewList.data.length);
  }

  if (productDetail?.load) {
    return <IsLoading />
  }

    return (
      <>
        <Left>
          <Main>
            <Image src={bigImage[stt]} alt="" />
          </Main>
          <Thumbnails>
            {bigImage?.map((item, index) => (
              <Thumbnail key={index} onClick={() => handletab(index)}>
                <Image src={item} alt="images" />
              </Thumbnail>
            ))}
          </Thumbnails>
        </Left>
        <Right>
          <CatLabel>
            Trang Chủ {">"} {productDetail?.data?.category?.name}
          </CatLabel>
          <TitleDetail>{productDetail?.data?.name}</TitleDetail>
          <Rate
            style={{ color: "#F6A500" }}
            value={renderRateList()}
            disabled
          />{" "}
          <span style={{ color: "#F6A500", marginLeft: "1rem" }}>
            ({reviewList?.data?.length} đánh giá)
          </span>
          <Price>
            Giá:{" "}
            {parseFloat(productDetail?.data?.price)
              .toFixed(3)
              .toLocaleString("VN_vi")}{" "}
            VNĐ
          </Price>
          {productDetail?.data?.countInStock > 0 && (
            <div>
              <p>
                Số lượng:{" "}
                <InputNumber
                  min={1}
                  max={productDetail?.data?.countInStock}
                  defaultValue="1"
                  onChange={onChange}
                />
              </p>
            </div>
          )}{" "}
          <br />
          <InStock>
            Status:{" "}
            {productDetail?.data?.countInStock > 0 ? "Còn hàng" : "Hết hàng"}
          </InStock>
          {productDetail?.data?.countInStock === 0 ? (
            <LinkWrapper
              to="#"
              className="disabled"
              onClick={(event) => event.preventDefault()}
            >
              Thêm vào giỏ hàng
            </LinkWrapper>
          ) : (
            <LinkWrapper className="white" onClick={() => handleAddToCart()}>
              Thêm vào giỏ hàng
            </LinkWrapper>
          )}
          <Heading>Ưu đãi liên quan</Heading>
          <Discription>{/* ... */}</Discription>
        </Right>
      </>
    );
}

export default ProductInfo;
