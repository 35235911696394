import React from 'react';
import styled from 'styled-components';

const TitleWrapper = styled.div`
  /* text-align: center; */
  /* margin-bottom: 5rem; */
  display: flex;
  align-items: center;
  padding-left: 20px;
  background-color: #FCDAB0;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
`
const LabelContain = styled.div`
  & img {
    width: 24px;
    height: 24px;
  }
`

const Heading = styled.h1`
  font-size: 1.8rem;
  font-weight: 500;
  /* margin-bottom: 1.5rem; */
  margin-bottom: 0rem !important;
  /* background-color: #ffff; */
  padding: 1rem;
  text-transform: uppercase;
`
const SubHeading = styled.span`
  color: var(--grey2);
`

const Title = ({ title, subtitle }) => {
  return (
    <TitleWrapper>
      <LabelContain>
        <img
          alt=""
          src="https://cdn0.fahasa.com/media/wysiwyg/icon-menu/icon_dealhot_new.png"
          className="center icon-header"
        />
      </LabelContain>
      <Heading>{title}</Heading>
      {/* <SubHeading>{subtitle}</SubHeading> */}
    </TitleWrapper>
  );
}

export default Title