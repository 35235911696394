import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  addToCartAction,
  addToReviewAction,
  getReviewListAction,
  getAllReviewListAction,
  getProductDetailAction,
  getProductSameAction,
} from "../../redux/actions";
import ProductInfo from "./ProductInfo";
import ProductReview from "./ProductReview";
import RelatedProducts from "./RelatedProducts";
import "./styles.css";
import { ProductDetails } from "./ProductInfoStyles";

const ProductDetailPage = ({
  productDetail,
  getProductDetail,
  getReviewList,
  getAllReviewList,
  reviewList,
  allReviewList,
  match,
  productSame,
  getProductSame,
  cartList,
  addToCart,
  addToReview,
}) => {
  const productId = match.params.id;

  useEffect(() => {
    if (productId) {
      getProductDetail({ id: productId });
      getReviewList({ productId: productId });
    }
  }, [productId, getProductDetail, getReviewList]);

  return (
    <>
      <ProductDetails className="container">
        <ProductInfo
          productDetail={productDetail}
          reviewList={reviewList}
          cartList={cartList}
          addToCart={addToCart}
          productId={productId}
        />
      </ProductDetails>

      <ProductReview
        reviewList={reviewList}
        productDetail={productDetail}
        addToReview={addToReview}
        productId={productId}
      />
      <RelatedProducts
        productDetail={productDetail}
        productSame={productSame}
        getProductSame={getProductSame}
        getAllReviewList={getAllReviewList}
        allReviewList={allReviewList}
        
        addToCart={addToCart}
        cartList={cartList}
        productId={productId}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { productDetail, productSame } = state.productReducer;
  const { cartList } = state.cartReducer;
  const { reviewList, allReviewList } = state.reviewReducer;
  return {
    productDetail,
    productSame,
    cartList,
    reviewList,
    allReviewList
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductDetail: (params) => dispatch(getProductDetailAction(params)),
    getProductSame: (params) => dispatch(getProductSameAction(params)),
    addToCart: (params) => dispatch(addToCartAction(params)),
    getReviewList: (params) => dispatch(getReviewListAction(params)),
    addToReview: (params) => dispatch(addToReviewAction(params)),
    getAllReviewList: (params) => dispatch(getAllReviewListAction(params)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailPage);
