import styled from "styled-components";
import { Link } from "react-router-dom";

export const ProductDetails = styled.div`
  display: grid;
  padding: 2rem;
  grid-template-columns: 1fr 1.4fr;
  /* gap: 7rem; */
  margin: 3rem auto;
  border-radius: 0.5rem;
  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    /* gap: 3rem; */
  }
  background-color: white;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  /* border: 1px solid gray; */
  @media (max-width: 650px) {
    width: 100%;
    /* height: 45rem; */
  }
`;

export const Main = styled.div`
  text-align: center;
  /* background-color: #f6f2f1; */
  margin-bottom: 1rem;
  height: 392px;
  /* padding: 3rem; */
  border: 1px solid #e6e4e4;
  border-radius: 0.5rem;
  cursor: pointer;
  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
    max-height: 392px;
  }
`;

export const Thumbnails = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  width: 100%;
`;

export const Thumbnail = styled.div`
  height: 10rem;
  /* background-color: #f6f2f1; */
  text-align: center;
  border: 1px solid #e6e4e4;
  border-radius: 0.5rem;
  cursor: pointer;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  :hover {
    border: 1px solid #2489F4;
  }
  @media (max-width: 650px) {
    /* width: 6rem; */
    height: 6rem;
  }
`;

export const Right = styled.div`
  padding-left: 36px;
  div {
    /* position: relative; */
    z-index: 1;
  }
  @media (max-width: 650px) {
    /* margin-top: 5rem; */
    padding-left: 0px;
  }
`;

export const CatLabel = styled.span`
  display: inline-block;
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

export const TitleDetail = styled.h1`
  font-size: 2.5rem;
  line-height: 1.2;
  margin-bottom: 2rem;
`;

export const Price = styled.div`
  font-size: 2.5rem;
  color: #C92127;
  margin-bottom: 2rem;
`;

export const InStock = styled.span`
  display: block;
  margin-bottom: 2rem;
`;

export const LinkWrapper = styled.div`
  /* display: inline-block;
  background: #ff4d4f;
  padding: 0.8rem 4rem;
  color: var(--white); */
  border-radius: 3rem;
  margin-bottom: 2rem;
  /* outline: none; */
  height: 44px;
  width: 220px;
  font-weight: 700;
  font-size: 1.1em;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #C92127;
  border: none;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 100%;
    max-width: 60rem;
  }
  &.disabled {
    cursor: default;
    pointer-events: none;
    color: var(--white);
    background: var(--grey1);
  }
  :hover {
    color: var(--white);
  }
`;

export const Discription = styled.p`
  color: var(--grey1);
  text-align: justify;
`;

export const Heading = styled.h3`
  border-top: 1px solid #e6e4e4;
  margin-top: 1px;
  padding-top: 1rem;
  /* text-transform: uppercase; */
  margin-bottom: 1rem;
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0.6rem;
  background-color: #e7e7e7;
  border-radius: 50%;
  padding: 1.3rem 1.6rem;
  transition: all 300ms ease-in-out;
  :hover {
    background-color: var(--primary);
    color: var(--white);
  }
  &.disabled {
    /* pointer-event: none; */
    cursor: default;
  }
`;

export const WrapperProduct = styled.div`
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 3rem; */
  /* padding: 0 10rem; */
  margin-bottom: 5rem;
`;

export const ProductItem = styled.div`
  /* flex: 1 0 calc(30% - 3rem); */
  /* margin-bottom: 3rem; */
  overflow: hidden;
  padding: 2rem 0;
  i {
    transition: all 300ms ease-in-out;
  }
  :hover {
    box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.1);
  }
  :hover ${IconWrapper} {
    border-radius: 1rem 0 0 0;
  }
`;

export const ImgContainer = styled.div`
  position: relative;
  cursor: pointer;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

export const Bottom = styled.div`
  padding: 1rem;
  /* text-align: center; */
`;

export const ProductLink = styled(Link)`
  margin-bottom: 1rem;
  font-weight: inherit;
  font-size: 1.5rem;
  color: var(--black);
  :hover {
    color: var(--primary);
  }
`;

export const PriceRelated = styled.div``;
export const PriceLabel = styled.span`
  color: var(--primary);
  font-size: 1.8rem;
`;

export const LabelSale = styled.div `
  position: absolute;
  background-color: #F7941E;
  right: 0;
  top: 2px;
  width: 46px;
  height: 46px;
  border-radius: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 1.3rem 1.6rem; */
  span {
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
  }
`;
