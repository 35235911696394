// ProductList.js

import React from 'react';
import { Link } from 'react-router-dom';
import { Rate } from 'antd';
import styled from 'styled-components';
import Image from '../../pages/Image/Image';
import IsLoading from '../IsLoading';

const IconWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0.6rem;
  background-color: #e7e7e7;
  border-radius: 50%;
  padding: 1.3rem 1.6rem;
  transition: all 300ms ease-in-out;
  :hover {
    background-color: var(--primary);
    color: var(--white);
  }
  &.disabled {
    /* pointer-event: none; */
    cursor: default;
  }
`;

const ProductItem = styled.div`
  overflow: hidden;
  padding: 1rem;
  /* margin-bottom: 2rem; */
  i {
    transition: all 300ms ease-in-out;
  }
  :hover {
    box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.1);
    /* border-radius: 0.5rem; */
  }
  :hover ${IconWrapper} {
    border-radius: 1rem 0 0 0;
  }
`;

const ImgContainer = styled.div`
  position: relative;
  cursor: pointer;
  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
    max-height: 190px;
  }
`;

const LabelSale = styled.div `
  position: absolute;
  background-color: #F7941E;
  right: 0;
  top: 2px;
  width: 46px;
  height: 46px;
  border-radius: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 1.3rem 1.6rem; */
  span {
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
  }
`;

const Bottom = styled.div`
  padding: 1rem;
  text-align: left;
`;

const ProductLink = styled(Link)`
  margin-bottom: 1rem;
  font-weight: inherit;
  font-size: 1.5rem;
  color: var(--black);
  :hover {
    color: var(--primary);
  }
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Price = styled.div``;
const PriceLabel = styled.span`
  color: var(--primary);
  font-size: 1.8rem;
  font-weight: 500;
`;


const ProductComponentItem = ({
  productList,
  allReviewList,
  handleAddToCart,
  // filteredProducts, // new prop for different logic
  productSameItem
}) => {

  function renderProductList() {
    if (productList?.load)
      return <IsLoading />

    // const productsToRender = filteredProducts? filteredProducts: productList?.data;
    // Chọn danh sách sản phẩm dựa trên điều kiện có productList hay productSameItem
    const productsToRender = productSameItem ? [productSameItem] : ( productList?.data);


    return productsToRender?.map((productItem, productIndex) => {
      let totalRate = 0;
      let count = 0;
      allReviewList?.data?.forEach((item) => {
        if (productItem.id === item.productId) {
          totalRate = totalRate + item.rate;
          count += 1;
        }
      });

      return (
        <ProductItem key={productIndex}>
          <ImgContainer>
            <Link to={`/product/${productItem.id}`}>
              <Image src={productItem.image[0]} alt={productItem.name} />
            </Link>
            {productItem.countInStock === 0 ? (
              <IconWrapper className="disabled">
                <i className="fas fa-shopping-cart"></i>
              </IconWrapper>
            ) : (
              <IconWrapper
                onClick={() =>
                  handleAddToCart(
                    productItem.id,
                    productItem.name,
                    productItem.price,
                    productItem.image
                  )
                }
              >
                <i className="fas fa-shopping-cart"></i>
              </IconWrapper>
            )}
            {productItem.labelSale && (
              <LabelSale>
                <span>{productItem.labelSale}</span>
              </LabelSale>
            )}
          </ImgContainer>
          <Bottom>
            <div style={{ width: "100%", height: "4.2rem" }}>
              <ProductLink to={`/product/${productItem.id}`} title={productItem.name}>
                {productItem.name}
              </ProductLink>
            </div>
            <Price>
              <PriceLabel>
                {parseFloat(productItem.price)
                  .toFixed(3)
                  .toLocaleString("VN_vi")}{" "}
                đ
              </PriceLabel>
            </Price>
            <div
              style={{ display: "flex", color: "#F6A500", fontWeight: "500" }}
            >
              <div>
                <Rate
                  style={{
                    color: "#F6A500",
                    fontSize: "18px",
                    lineHeight: "20px",
                  }}
                  value={count !== 0 ? Math.ceil(totalRate / count) : 0}
                  disabled
                />
              </div>
              <div>({count})</div>
            </div>
          </Bottom>
        </ProductItem>
      );
    });
  }

  return (
    <>{renderProductList()}</>
  );
};

export default ProductComponentItem;
