import React from "react";

export default function IsLoading() {

  return (
    <div>
      <div
        className="overlay"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 9998,
        }}
      ></div>
      <div
        className="loading-container"
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9999,
        }}
      >
        <img
          src="https://cdn0.fahasa.com/media/theme/default/loader.gif"
          alt=""
        />
      </div>
    </div>
  );
}
