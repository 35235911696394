import React, { useEffect, useState } from "react";
import ProductComponentItem from "../../components/ProductItem";
import Title from "../../Title";
import {
  WrapperProduct,
} from "./ProductInfoStyles";
import { Button, message, notification } from "antd";
import history from "../../utils/history";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function RelatedProducts({
  productDetail,
  productSame,
  addToCart,
  cartList,
  productId,
  getProductSame,
  allReviewList,
  getAllReviewList,
}) {
  const [currentProduct, setCurrentProduct] = useState(null);
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    if (productDetail.data.categoryId) {
      getProductSame({ categoryId: productDetail.data.categoryId });

      setCurrentProduct(productDetail.data);
    }
    getAllReviewList();
  }, [getProductSame, productDetail.data, getAllReviewList]);

  if (!currentProduct) {
    return null;
  }
  const filteredProducts = productSame?.data?.filter(
    (item) => item.id !== currentProduct.id
  );

  function handleAddToCart(id, name, price, image) {
    if (!userInfo) {
      const key = `open${Date.now()}`;
      return notification.warning({
        message: "Chưa đăng nhập",
        description: "Bạn cần đăng nhập để thêm vào giỏ hàng",
        key,
        btn: (
          <Button
            type="primary"
            onClick={() => {
              notification.close(key);
              history.push("/login");
            }}
          >
            Đăng nhập
          </Button>
        ),
      });
    } else {
      const existProductIndex = cartList.data.findIndex(
        (item) => item.productId === parseInt(id)
      );
      if (existProductIndex !== -1) {
        const newCartList = cartList.data;
        newCartList.splice(existProductIndex, 1, {
          productId: parseInt(id),
          count: cartList.data[existProductIndex].count + 1,
          name,
          price,
          image
        });
        addToCart({
          userId: userInfo.id,
          carts: newCartList,
        });
        message.success('Cập nhật giỏ hàng thành công!')
      } else {
        addToCart({
          userId: userInfo.id,
          carts: [
            ...cartList.data,
            {
              productId: parseInt(id),
              count: 1,
              name,
              price,
              image
            },
          ],
        });
        message.success('Thêm vào giỏ hàng thành công!')
      }
    }
  }

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  // const renderRelatedProducts = () => {
  //   if (!currentProduct) {
  //     return null;
  //   }
  //   const filteredProducts = productSame.data.filter(
  //     (item) => item.id !== currentProduct.id
  //   );

  //   return filteredProducts.map((productSameItem, productSameIndex) => (
  //     <ProductItem key={productSameIndex}>
  //       <ImgContainer>
  //         <Link to={`/product/${productSameItem.id}`}>
  //           <Image src={productSameItem.image[0]} alt={productSameItem.name} />
  //         </Link>
  //         {productSameItem.countInStock === 0 ? (
  //           <IconWrapper className="disabled">
  //             <i className="fas fa-shopping-cart"></i>
  //           </IconWrapper>
  //         ) : (
  //           <IconWrapper>
  //             <i className="fas fa-shopping-cart"></i>
  //           </IconWrapper>
  //         )}
  //         {productSameItem.labelSale && (
  //             <LabelSale>
  //               <span>{productSameItem.labelSale}</span>
  //           </LabelSale>
  //           )}
  //       </ImgContainer>
  //       <Bottom>
  //         <ProductLink to={`/product/${productSameItem.id}`}>
  //           {productSameItem.name}
  //         </ProductLink>
  //         <PriceRelated>
  //           <PriceLabel>
  //             {parseInt(productSameItem.price)
  //               .toFixed(3)
  //               .toLocaleString("VN_vi")}
  //             đ
  //           </PriceLabel>
  //         </PriceRelated>
  //       </Bottom>
  //     </ProductItem>
  //   ));
  // };

  return (
    <>
      <section className="container">
        <Title title="SẢN PHẨM CÙNG MUA" subtitle="Chossen Goods Books" />
        <WrapperProduct
          style={{ backgroundColor: "white"}}
        >
          {/* {renderRelatedProducts()} */}
          <Slider {...settings}>
            {/* <ProductComponentItem
              filteredProducts={filteredProducts}
              allReviewList={allReviewList}
              handleAddToCart={handleAddToCart}
              /> */}
            {filteredProducts?.map((productSameItem, productSameIndex) => (
              <ProductComponentItem
                key={productSameIndex}
                productSameItem={productSameItem}
                allReviewList={allReviewList}
                handleAddToCart={handleAddToCart}
              />
            ))}
            {/* Add empty slides if there are fewer than 5 products */}
            {Array.from({
              length: Math.max(0, 5 - (filteredProducts?.length || 0)),
            }).map((_, index) => (
              <div key={`empty-${index}`} />
            ))}
          </Slider>
        </WrapperProduct>
      </section>
    </>
  );
}

export default RelatedProducts;
