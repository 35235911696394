import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Rate, Input, Button, Tabs, message } from "antd";
import moment from "moment";

function ProductReview({
  reviewList,
  productDetail,
  addToReview,
  productId,
}) {
  const { TabPane } = Tabs;
  const { TextArea } = Input;
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [review, setReview] = useState();
  const [rate, setRate] = useState();
  // const productId = match.params.id;

  function onChangeText(e) {
    setReview(e.target.value);
  }

  function callback(key) {
    console.log(key);
  }

  function handleAddToReview() {
    if (rate && review) {
      const newReview = {
        name: userInfo.name,
        rate: rate,
        review: review,
        date: moment().format("hh:mm DD/MM/YYYY"),
      };
      setRate("");
      addToReview({
        newReview: newReview,
        userId: userInfo.id,
        productId: parseInt(productId),
      });
      message.success("Cảm ơn bạn đã góp ý cho sản phẩm của chúng tôi");
    } else {
      message.warning("Bạn cần nhập đánh giá đầy đủ");
    }
  }

  function renderReviewList() {
    return reviewList.data.map((item, index) => {
      return (
        <div className="comment-content" key={index}>
          <ul className="comment-list">
            <li>
              <div className="avt">
                {/* <Avatar size={50} icon={<UserOutlined />} /> */}
                <h4>{item.name}</h4>
              </div>
              <div className="rating-box">
                <div>Được đánh giá vào lúc {item.date}</div>
                <div>
                  <Rate
                    style={{ color: "#F6A500" }}
                    value={item.rate}
                    disabled
                  />
                </div>
                <p>{item.review}</p>
              </div>
            </li>
          </ul>
        </div>
      );
    });
  }

  const [showFullDescription, setShowFullDescription] = useState(false);

  // Số ký tự tối đa muốn hiển thị ban đầu
  const maxLength = 500;
  if (!productDetail || !productDetail?.data) {
    return null
  }
  
  const description = productDetail?.data?.description;
  // const descriptionLength = description ? description.length : 0;
  // Logic để quyết định xem có nên hiển thị "xem thêm" hay không
  const shouldShowMoreButton = description?.length > maxLength


  // Function để xem thêm hoặc thu gọn
  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  // Lấy phần mô tả cần hiển thị
  const displayDescription =
    showFullDescription || !shouldShowMoreButton ? description : `${description.slice(0, maxLength)}...`;

  return (
    <div className="container content-comment">
      <Tabs defaultActiveKey="1" size="large" onChange={callback}>
        <TabPane tab="Thông tin sản phẩm" key="1">
          <div className="container-info">
            <div>
              <h4>Mã hàng: </h4>
              <p>{productDetail?.data?.code}</p>
            </div>
            <div>
              <h4>Tên nhà cung cấp: </h4>
              <p>{productDetail?.data?.publisher.name}</p>
            </div>
            <div>
              <h4>Tác giả: </h4>
              <p>{productDetail?.data?.author}</p>
            </div>
            <div>
              <h4>Năm xuất bản: </h4>
              <p>{productDetail?.data?.publicYear}</p>
            </div>
            <div>
              <h4>Trọng lượng (g): </h4>
              <p>{productDetail?.data?.weight}</p>
            </div>
            <div>
              <h4>Kích thước bao bì: </h4>
              <p>{productDetail?.data?.size}</p>
            </div>
            <div>
              <h4>Số trang: </h4>
              <p>{productDetail?.data?.numberPages}</p>
            </div>
            <div>
              <h4>Hình thức: </h4>
              <p>{productDetail?.data?.formality}</p>
            </div>
          </div>
          <div
            className={`descrip ${showFullDescription ? 'expanded' : ''}`}
            dangerouslySetInnerHTML={{
              // __html: productDetail.data.description,
              __html: displayDescription,
            }}
          ></div>
          {shouldShowMoreButton && (
            <div onClick={toggleDescription} className="btn__more">
              {showFullDescription ? "Rút Gọn" : "Xem Thêm"}
            </div>
          )}
        </TabPane>
        <TabPane tab="Khách hàng nhận xét" key="2">
          {userInfo ? (
            <div className="container-rating">
              <div>
                <p>Bạn đánh giá sản phẩm này thế nào ?</p>
                <p>
                  <Rate
                    style={{ color: "#F6A500" }}
                    allowHalf
                    onChange={(value) => {
                      setRate(value);
                    }}
                    value={rate}
                  />
                </p>
                <div style={{ display: "flex" }}>
                  <TextArea
                    value={review}
                    showCount
                    maxLength={1000}
                    autoSize={{ minRows: 2 }}
                    placeholder="Nhập vào nhận xét của bạn về sản phẩm"
                    style={{ maxWidth: "1400px", width: "100%" }}
                    onChange={onChangeText}
                  />
                  <Button
                    style={{ height: "54px", marginLeft: "18px" }}
                    type="primary"
                    onClick={() => {
                      handleAddToReview();
                      setReview("");
                    }}
                  >
                    Đánh giá{" "}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <p style={{ textAlign: "center" }}>
              Chỉ có thành viên mới có thể viết nhận xét. Vui lòng{" "}
              <Link to="/login">đăng nhập</Link> hoặc{" "}
              <Link to="/register">đăng ký</Link>
            </p>
          )}
          {renderReviewList()}
        </TabPane>
      </Tabs>
    </div>
  );
}

export default ProductReview;
